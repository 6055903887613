<template>
    <div class="year-list flex">
        <div :class="{'disabled':currentItem == 0}" @click="handlePrevEvent" class="btn-direction med med-left"></div>
        <div class="flex swiper-content" :style="{transform:'translateX('+(-80*currentItem)+'px)'}" > 
            <div class="flex">
                <div class="flex x-left">
                    <div v-for="(item,index) in list" :key="index" class="year-item t-c colorfff" :class="{'year-bg':current == index}" @click="getPaperList(item,index)">
                        <span v-if="type == '1'">{{item}}</span>
                        <span v-if="type == '2'">{{item}}期</span>
                    </div>
                </div>
            </div>
            
        </div>
        <div :class="{'disabled':currentItem == list.length - 7}" @click="handleNextEvent" class="btn-direction med med-right"></div>
    </div>
</template>

<script>
export default {
    name: "year-list",
    props: {
        type:{
            type:String,
            default:'1'
        },
        list:{
            type:Array,
            defalut:() => {
                //    {
                //        id:1,
                //        title:'',
                //        img:'',
                //        num:0,
                //        is_collection:1,
                //    }
            }
        },
        filterList:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return {
            current:0,
            currentItem:0,
            yearList:[]
        }
    },
    created(){
    },
    methods:{
        handlePrevEvent(){
            if(this.currentItem > 0){
                --this.currentItem;
            }
        },
        handleNextEvent(){
            if(this.list.length - 7 > this.currentItem){
                ++this.currentItem;
            }
        },
        getPaperList(item,index){
            this.current = index
            this.$emit('getNumList',item)
        }
    }

};
</script>

<style scoped lang="less">
.year-list{
    width: 590px;
    position: relative;
    overflow: hidden;
    .year-item{
        width: 60px;
        height: 22px;
        line-height: 22px;
        background: #D8D8D8;
        border-radius: 11px;
        margin-right: 20px;
        cursor: pointer;
    }
    .year-bg{
        background: linear-gradient(to right, #5F96E6,#1E51C9);
    }
    .swiper-content{
        width: 550px;
        height: 22px;
        margin-left: 26px;
        flex-wrap:nowrap;
        transition: all 0.15s linear;
    }
    .btn-direction{
        cursor: pointer;
        position: absolute;
        top: 0;bottom: 0;
        width:16px;
        height:22px;
        line-height: 22px;
        text-align: center;
        background: #ffffff;
        color:#ccc;
        font-size:16px;
        z-index:2;
        &.disabled{
            &.med-left{
                color: #D8D8D8;
            }
            &.med-right{
                color: #D8D8D8;
            }
        }
        &.med-left{
            color: #1E51C9;
            left:0;
        }
        &.med-right{
            color: #1E51C9;
            right:0;
        }
    }
}
</style>
